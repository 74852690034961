import { LockFormWithCookies } from "./LockForm";
import { useQueryParam, StringParam } from "use-query-params";
import { ReactComponent as Lock } from "./lock.svg";

export const LockPage = () => {
  const [subdomain] = useQueryParam("subdomain", StringParam);

  return (
    <div className="row m-auto flex-row-reverse cpl-w-content justify-content-around p-auto">
      <div className="col-xl-6 col-lg-8 col-md-10 col-12 text-center right-side pt-8">
        <Lock className="d-block mx-auto mb-4 mb-md-6 img-fluid cpl-img-fluid" />
      </div>
      <div className="col-xl-6 col-lg-8 col-md-10 col-12 text-center cpl-left-side">
        <div className="d-flex flex-column h-100">
          <div>
            <div className="font-display mb-2 text-gray-900 font-weight-bold d-xl-flex cpl-lock-title">
              Sorry, this website
              <br />
              is hidden right now.
            </div>
          </div>
          <LockFormWithCookies />
          <div>
            <div className="text-align text-gray-500 button-footer text-sm-mq-down-sm">
              Forgot your code?&nbsp;
              <a
                href={"https://my.elementor.com/websites/" + (subdomain || "")}
                className="link-secondary-highlight"
              >
                {" "}
                Find it on your dashboard
              </a>
            </div>
            <div className="text-align text-gray-500 button-footer text-sm-mq-down-sm">
              Not your site? Contact this site’s owner.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
