import { LockPage } from "./components/LockPage";
import logo from "./components/logo.svg";

function App() {
  const subdomain = window.location.hostname.split(".")?.[0] || "unknown";

  return (
    <div className="container-fluid bg-light px-0">
      <div className="d-flex flex-column align-items-center min-vh-100">
        <div className="flex-fill d-flex align-items-center w-100">
          <div className="container-fluid px-0">
            <LockPage />
          </div>
        </div>
        <div className="pb-6 pb-md-8">
          <a
            href={`https://elementor.com/#utm_source=cloud-site&utm_medium=site-lock-footer&utm_campaign=site-lock&utm_term=${subdomain}`}
            className="d-flex align-items-center"
          >
            <span className="text-gray-600 text-sm mr-2">Powered by</span>
            <img
              className="align-text-bottom"
              alt="logo"
              height="20"
              src={logo}
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
